import { Logo } from "../utils/icons";

function Header() {
  return (
    <header role="banner" className="ui-section-header">
      <div className="ui-layout-container">
        <div className="ui-section-header__layout ui-layout-flex">
          {/* <!-- LOGO --> */}
          <a href="/" aria-label="#">
            <Logo />
          </a>
          {/* <!-- MENU --> */}
          {/* <a href="#" aria-label="#">
            <svg viewBox="0 0 24 24" height="18" width="18" fill="none" stroke="#353535" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" role="img" aria-label="#">
              <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
            </svg>
          </a> */}
        </div>
      </div>
    </header>
  )
}

export default Header;