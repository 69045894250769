import { useRef, useState } from "react";
import axios from "axios";
import FileSaver from "file-saver";

import { StepOne, StepTwo, StepThree } from "../utils/icons";

function Content() {
  const codeRef = useRef();
  const [status, setStatus] = useState();

  function handleCode() {
    const code = codeRef.current.value;

    if(code.length <= 10) {
      return setStatus("Invalid code");
    }

    setStatus("Loading...");

    axios({
      method: 'post',
      responseType: 'blob',
      url: `${process.env.REACT_APP_API_URL}/bought`,
      data: {
        code
      }
    })
    .then(function (response) {
      console.log(response);
      setStatus("Code redeemed 🎉");
      FileSaver.saveAs(response.data, "qawolf.zip");
    })
    .catch(function (error) {
      console.log(error);
      setStatus("An error occurred, please try again");
    });
  }

  return (
    <main role="main">
      <section className="ui-section-content">
        <div className="ui-layout-container">
          {/* <!-- COPYWRITING --> */}
          <div className="ui-layout-column-6 ui-layout-column-center">
            <h1>Zero effort QA for startups</h1>
            <p className="ui-text-intro">QA Wolf handles your end-to-end testing so your team can focus on shipping</p>
            {/* <!-- CTA --> */}
            <div className="ui-component-cta ui-layout-flex">
              <form action="#" className="ui-component-form ui-layout-grid ui-layout-column-4">
                <input type="text" ref={codeRef} placeholder="Paste Code" className="ui-component-input ui-component-input-medium" required />
                <button type="button" className="ui-component-button ui-component-button-medium ui-component-button-primary" onClick={handleCode}>Download</button>
              </form>
              <p className="ui-text-note"><small>{status??null}</small></p>
            </div>
          </div>
          {/* <!-- IMAGE --> */}
          {/* <img src="https://res.cloudinary.com/uisual/image/upload/assets/devices/ipad.png" loading="lazy" alt="#" className="ui-section-content--image" /> */}
          <video loop autoPlay muted className="ui-section-content--video">
            <source src="assets/qa_test.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* <!-- FEATURE --> */}
          <div className="ui-section-content--feature ui-layout-grid ui-layout-grid-3">
            <div>
              <StepOne />
              <h3>1. Establish test plan</h3>
              <p>We prioritize a list of user flows to test based on your product</p>
            </div>
            <div>
              <StepTwo />
              <h3>2. Create tests</h3>
              <p>We get you to 80% end-to-end test coverage and integrate into CI</p>
            </div>
            <div>
              <StepThree />
              <h3>3. Maintain tests</h3>
              <p>We update your tests as needed to find bugs before your customers do</p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="ui-section-close">
        <div className="ui-layout-container">
          <div className="ui-layout-column-6 ui-layout-column-center">
            <h2>Ready to start?</h2>
            <p className="ui-text-intro">Lorem ipsum dolor sit amet consectetur.</p>
            <div className="ui-component-cta ui-layout-flex">
              <form action="#" className="ui-component-form ui-layout-grid ui-layout-column-4">
                <input type="email" placeholder="Email" className="ui-component-input ui-component-input-medium" required />
                <button type="submit" className="ui-component-button ui-component-button-medium ui-component-button-primary">Download</button>
              </form>
              <p className="ui-text-note"><small>Available on Mac, Windows, and Linux.</small></p>
            </div>
          </div>
        </div>
      </section> */}
    </main>
  )
}

export default Content;