import Content from "../components/Content";
// import Footer from "../components/Footer";
import Header from "../components/Header";

function Home() {
  return (
    <>
      <Header />
      <Content />
      {/* <Footer /> */}
    </>
  )
}

export default Home;